import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Spinner } from 'react-bootstrap';
import CheckBoxDropDownSelector from '../components/MktActionsFilterBarComps/CheckBoxDropDownSelector';

function SpecialSignUpFormModal({ show, onHide, onSubmitted, workspaceHint }) {

    const [workspaceOptions, setWorkspaceOptions] = useState(() => {
        const initial = {
            'CE (public)': false,
            'GHMH (public)': false
        };
        if (workspaceHint && initial.hasOwnProperty(workspaceHint)) {
            initial[workspaceHint] = true;
        }
        return initial;
    });
    
    const [form, setForm] = useState({
        email: '',
        company: '',
        jobtitle: '',
        additional_information: '',
        workspace_requested: Object.keys(workspaceOptions).filter(k => workspaceOptions[k])
    });
    

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        const selected = Object.keys(workspaceOptions).filter(k => workspaceOptions[k]);
        setForm(prev => ({ ...prev, workspace_requested: selected }));
    }, [workspaceOptions]);

    const handleClose = () => {
        setIsSubmitted(false);
        setForm({ email: '', company: '', jobtitle: '', additional_information: '', workspace_requested: [] });
        setErrors({});
        onHide();
    };

    const setField = (field, value) => {
        setForm(prev => ({ ...prev, [field]: value }));
        if (errors[field]) {
            setErrors(prev => ({ ...prev, [field]: null }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!form.email) newErrors.email = 'Required';
        if (!form.company) newErrors.company = 'Required';
        if (!form.jobtitle) newErrors.jobtitle = 'Required';
        if (!form.workspace_requested.length) newErrors.workspace_requested = 'Please select a workspace';
        return newErrors;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const validation = validateForm();
        if (Object.keys(validation).length > 0) {
            setErrors(validation);
            return;
        }

        setIsSubmitting(true);

        const payload = {
            portalId: '8615684',
            guid: '639c0fb4-a463-46b3-830b-39a49d69f87b',
            fields: [
                { name: 'email', value: form.email },
                { name: 'company', value: form.company },
                { name: 'jobtitle', value: form.jobtitle },
                { name: 'additional_information', value: form.additional_information },
                { name: 'workspace_requested', value: form.workspace_requested.join(', ') }
            ],
            legalConsentOptions: {
                consent: {
                    consentToProcess: true,
                    text: 'I agree to allow Linksbridge to store and process my personal data.',
                    communications: [
                        {
                            value: true,
                            subscriptionTypeId: 999,
                            text: 'I agree to receive marketing communications from Linksbridge.'
                        }
                    ]
                }
            }
        };

        try {
            await fetch(`https://api.hsforms.com/submissions/v3/integration/submit/8615684/639c0fb4-a463-46b3-830b-39a49d69f87b`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            });
            setIsSubmitted(true);
            onSubmitted?.(true);
        } catch (err) {
            console.error('Submission failed:', err);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size="md" centered>
            <Modal.Header closeButton>
                <Modal.Title>Workspace access request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isSubmitted ? (
                    <Form onSubmit={onSubmit}>
                        <p>Our team will respond within 1–3 business days.</p>

                        <Form.Group className="mb-3">
                            <Form.Label>Your email *</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                value={form.email}
                                onChange={e => setField('email', e.target.value)}
                                isInvalid={!!errors.email}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Company name *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter company name here"
                                value={form.company}
                                onChange={e => setField('company', e.target.value)}
                                isInvalid={!!errors.company}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Job title *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Example: Student, Researcher, Consultant"
                                value={form.jobtitle}
                                onChange={e => setField('jobtitle', e.target.value)}
                                isInvalid={!!errors.jobtitle}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Workspaces to access *</Form.Label>
                            <div
                                className="form-control p-0"
                                style={{
                                    border: errors.workspace_requested ? '1px solid #dc3545' : undefined,
                                    borderRadius: '0.375rem'
                                }}
                            >
                                <CheckBoxDropDownSelector
                                    options={workspaceOptions}
                                    selected={Object.keys(workspaceOptions).filter(k => workspaceOptions[k])}
                                    displayOptionHandler={(e) => {
                                        const value = e.target.value;
                                        const isChecked = e.target.checked;
                                        setWorkspaceOptions(prev => ({
                                            ...prev,
                                            [value]: isChecked
                                        }));
                                    }}
                                    labelString={
                                        Object.keys(workspaceOptions).filter(k => workspaceOptions[k]).join(', ') || 'Select...'
                                    }
                                    selectAll={false}
                                    className='select-new-basic'
                                />
                            </div>
                            {errors.workspace_requested && (
                                <div className="invalid-feedback d-block">{errors.workspace_requested}</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Additional information</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter text here"
                                value={form.additional_information}
                                onChange={e => setField('additional_information', e.target.value)}
                            />
                        </Form.Group>

                        <p style={{ fontSize: '0.875rem', color: '#555' }}>
                            Linksbridge respects your privacy. We’ll only use your personal information to provide content you requested, offer necessary updates about our services, and administer your account.
                            <br /><br />
                            By clicking submit below, you consent to allow Linksbridge to store and process the personal information submitted above to provide you the content requested.
                        </p>

                        <Button
                            type="submit"
                            className="w-100 surface-brand-primary border-primary-dark text-primary-light"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? <Spinner size="sm" animation="border" /> : 'Submit Request'}
                        </Button>
                    </Form>
                ) : (
                    <>
                        <p>Thank you for submitting your request.  Our team will respond within 1-3 business days.</p>
                        <div className="text-center">
                            <Button variant="primary" onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
}

export default SpecialSignUpFormModal;
