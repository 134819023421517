import React from 'react';
import { Row, Card, Button, Image } from 'react-bootstrap';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { Suspense, lazy } from 'react';


import ToastProvider from '../components/ToastProvider';

import utils from '../utils';
import '../styles/main.scss';
import SigninLink from '../components/SigninLink';
// can't lazy load this because it breaks css styles (company profiles cards)
import NewsAppPage from '../pages/appPages/news/NewsAppPage';
import microsoftLogo from '../assets/microsoft-logo.png'
// can't lazy load this because it breaks css styles (market actions chart)
import MarketActionsMarketPage from '../pages/marketActions/MarketActionsMarketPage';   
import CONSTANTS from '../constants';
import Notification from '../components/Layout/Notification/Notification';
// can't lazy load this because it breaks css styles (demandHealth add country activity button)
import SuperAdminMgmtPage from '../pages/LogsApp/SuperAdminMgmtPage';
import PMTCONSTANTS from '../components/PMT/pmtConstants';


const MainAppPage = lazy(() => import ('../pages/appPages/MainAppPage'));
const ArticleDisplay = lazy(() => import ('../components/News/ArticleDisplay'));
const CoProfileMainPage = lazy(() => import ('../pages/companyProfiles/CoProfileMainPage'));
const SearchResultsPage = lazy(() => import ('../pages/SearchResultsPage'));
const IndustryAnalysisAppPage = lazy(() => import ('../pages/industryAnalysis/IndustryAnalysisAppPage'));
const VaccineMarketsAppPage = lazy(() => import ('../pages/vaccineMarkets/VaccineMarketsAppPage'));
const VaccineMarketsListPage = lazy(() => import ('../pages/vaccineMarkets/VaccineMarketsListPage'));
const IntelligenceAppPage = lazy(() => import ('../pages/intelligence/IntelligenceAppPage'));
const IntelligenceDisplay = lazy(() => import ('../components/Intelligence/IntelligenceDisplay'));
const IntellEditReport = lazy(() => import ('../components/Intelligence/IntellEditReport'));
const AlchemyAppPage = lazy(() => import ('../pages/Alchemy/AlchemyAppPage'));
const MarketActionsAppPage = lazy(() => import ('../pages/marketActions/MarketActionsAppPage'));
const PMTAppPage = lazy(() => import ('../pages/PMT/PMTAppPage'));
const AdrsPopEstAppPage = lazy(() => import ('../pages/AddrsPopEstimator/AdrsPopEstAppPage'));
const MetaAppPage = lazy(() => import ('../pages/meta/MetaAppPage'));
const PrtnrProfilesAppPage = lazy(() => import ('../pages/PrtnrProfiles/PrtnrProfilesAppPage'));
const PrtnrProfilesListPage = lazy(() => import ('../pages/PrtnrProfiles/PrtnrProfilesListPage'));
const SettingsPage = lazy(() => import ('../pages/SettingsPage'));
const LogsAppPage = lazy(() => import ('../pages/LogsApp/LogsAppPage'));
const EthiopiaCtryAppPage = lazy(() => import ('../pages/EthiopiaCountryApp/EthiopiaCtryAppPage'));
const NigeriaCtryAppPage = lazy(() => import ('../pages/NigeriaCountryApp/NigeriaCtryAppPage'));
const IntegrationAppPage = lazy(() => import ('../pages/IntegrationApp/IntegrationAppPage'));
const TopicNodePage = lazy(() => import ('../components/News/TopicNodePage'));
const IRCAppPage = lazy(() => import ('../pages/IRCApp/IRCAppPage'));
const CENewsAppPage = lazy(() => import ('../pages/CENews/CENewsAppPage'));
const CEArticleDisplay = lazy(() => import ('../components/CENews/CEArticleDisplay'));
const DemandHealthAppPage = lazy(() => import ('../pages/PMT/DemandHealthAppPage'));
const CampaignHubAppPage = lazy(() => import ('../pages/CampaignHub/CampaignHubAppPage'));
const ChineseMktAppPage = lazy(() => import ('../pages/ChineseMarket/ChineseMktAppPage'));


function SignInPageRoutes({ tenantSettings, notifications, apiError, tooltip, showNav, windowSize, curNav, firstTimeUser, accessToken, isAuthenticated, queryRequest }) {
  



    const history = useHistory();


    let { path } = useRouteMatch();

        // browser tabs
    const getPathnameFormat = (name) => {
        let pathname = name.split('/');
        pathname = pathname[1].toUpperCase();
        if (pathname) {
            document.title = `Workspace | ${CONSTANTS.APPS_BY_NAME[pathname]}`;
        } else {
            document.title = `Workspace | Home`
        }
        return pathname;
    }

    let urlPathname = window.location.pathname;
    getPathnameFormat(urlPathname)

    const getTooltipInfo = (tooltipArr) => {
        let tooltipItem;

        tooltipArr.forEach((item) => {
            if (item.fields.key === "sensitivityTooltip") {
                tooltipItem = item.fields.content;
            }
            return tooltipItem;
        })
        return tooltipItem
    }

    const getTooltipContentByKey = (tooltipArr, key) => {
        const tooltipItem = tooltipArr?.find(item => item.fields.key === key);
        if (!tooltipItem) {
            return 'No tooltip available';  // Return plain text for missing keys
        }
        
        const content = tooltipItem.fields.content;
        
        // Check if the content contains HTML tags
        if (/<[a-z][\s\S]*>/i.test(content)) {
            return utils.getHTML(content);  // Wrap HTML string in { __html: content }
        }
        
        return content;  // Return plain text directly
        };

    return (
    <>
        {
            notifications && notifications.data && notifications.data.length > 0 ?
                notifications.data.map((note) => {
                    return (
                        <Notification notificationType={note.fields.notificationType} content={note.fields} />
                    )
                })
                : ''
        }
        <ToastProvider>
            <Row className="main-body-text">
                {
                    tenantSettings && accessToken && isAuthenticated ?
                        <div className='isAuth-container'>
                            <div className={showNav ? 'isAuth-landing-page show' : 'isAuth-landing-page hide'}>
                                <Switch>
                                    <Route exact path={`${path}`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <MainAppPage
                                                tenantSettings={tenantSettings}
                                                token={accessToken}
                                                showNav={showNav}
                                                windowSize={windowSize}
                                                showFakes={firstTimeUser} />
                                        </Suspense>
                                    </Route>

                                    <Route exact path={`${path}settings`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <SettingsPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route exact path={`${path}logs`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <LogsAppPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route exact path={`${path}super_admin`}>
                                        <SuperAdminMgmtPage tenant={tenantSettings} />
                                    </Route>
                                    <Route exact path={`${path}news`}>
                                        <NewsAppPage tenant={tenantSettings} />
                                    </Route>
                                    <Route exact path={`${path}ce_news`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <CENewsAppPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}ce_news/:articleSlug`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <CEArticleDisplay workspace={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route exact path={`${path}topic/:topicSlug`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <TopicNodePage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}news/:storyID`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <ArticleDisplay tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}company_profiles/:slug?`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <CoProfileMainPage tenant={tenantSettings} token={accessToken} singularCompany={false} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}my_company_profile`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <CoProfileMainPage tenant={tenantSettings} token={accessToken} singularCompany={true} />
                                        </Suspense>
                                    </Route>
                                    <Route exact path={`${path}partner_profiles`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <PrtnrProfilesListPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}partner_profiles/:slug`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <PrtnrProfilesAppPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}search/:query`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <SearchResultsPage tenant={tenantSettings} query={queryRequest} curNav={curNav} />
                                        </Suspense>
                                    </Route>
                                    <Route exact path={`${path}glbl_hlth_alchemy`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <AlchemyAppPage tenant={tenantSettings} tooltip={tooltip} getTooltipContentByKey={getTooltipContentByKey} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}industry_analysis`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <IndustryAnalysisAppPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields&& tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "irc_app")}/:cntryISO?`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <IRCAppPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route exact path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "market_actions")}`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <MarketActionsAppPage tenant={tenantSettings} token={accessToken} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "market_actions")}/markets/:marketID`}>
                                        <MarketActionsMarketPage tenant={tenantSettings} token={accessToken} />
                                    </Route>
                                    <Route path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "pmt_ma")}`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <PMTAppPage tenant={tenantSettings} token={accessToken} appToMatch={PMTCONSTANTS.PMT_APPS["pmt_ma"]} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "pmt_demand")}`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <DemandHealthAppPage tenant={tenantSettings} token={accessToken} appToMatch={PMTCONSTANTS.PMT_APPS["pmt_demand"]} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}chinese_market`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <ChineseMktAppPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}addressable_pop`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <AdrsPopEstAppPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}campaign_hub`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <CampaignHubAppPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>                                   
                                    <Route path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "ethiopia_country_app")}`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <EthiopiaCtryAppPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "nigeria_country_app")}`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <NigeriaCtryAppPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "ce_integration_app")}`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <IntegrationAppPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}meta`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <MetaAppPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route exact path={`${path}vaccine_markets`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <VaccineMarketsListPage tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}vaccine_markets/:diseaseSlug`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <VaccineMarketsAppPage tenant={tenantSettings} token={accessToken} />
                                        </Suspense>
                                    </Route>
                                    <Route exact path={`${path}intelligence`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <IntelligenceAppPage tenant={tenantSettings} token={accessToken} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}intelligence/reports/:reportID`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <IntelligenceDisplay tenant={tenantSettings} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}intelligence/create`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <IntellEditReport type={'create'} tenant={tenantSettings} tooltip={getTooltipInfo(tooltip)} />
                                        </Suspense>
                                    </Route>
                                    <Route path={`${path}intelligence/edit/:reportID`}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <IntellEditReport type={'edit'} tenant={tenantSettings} tooltip={getTooltipInfo(tooltip)} />
                                        </Suspense>
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                        :
                        apiError ?
                            apiError === '503-Service Unavailable' ?
                                <div className="text-center">
                                    <h1>Setting Up Your Account</h1>
                                    <p>Please wait 30 seconds to a minute and refresh your browser.</p>
                                </div>

                                : <div className='signin-landing-page'>
                                    <Card className='welcome-back'>
                                        <p>{apiError}</p>
                                        <h5>Something's missing!</h5>
                                        <Button variant='primary' onClick={() => history.goBack()}>Go Back</Button>
                                    </Card>
                                </div> :
                            <div className='signin-landing-page'>
                                <Card className='welcome-back'>
                                    <h1>Welcome Back</h1>
                                    <p>Please sign in to view the application</p>
                                    <SigninLink>
                                        <Button style={{ margin: 'auto', cursor: 'pointer' }}>
                                            <Image
                                                src={microsoftLogo}
                                                alt='Microsoft Logo'
                                                style={{
                                                    width: "auto",
                                                    height: "1.25em"
                                                }}
                                            />
                                            Sign in with Microsoft
                                        </Button>
                                    </SigninLink>
                                </Card>
                            </div>
                }

            </Row>
        </ToastProvider>
    </>
  )
}

export default SignInPageRoutes