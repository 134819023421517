import React, { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Spinner, } from 'react-bootstrap';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';

import utils from '../utils';
import tenantUtils from '../tenantUtils';
import '../styles/main.scss'
import SignInPageRoutes from '../components/SignInPageRoutes';
import SupportModal from '../components/SupportModal';
import FirstTimeUserModal from '../components/FirstTimeUserModal';
import LgViewPrtLandPage from '../components/LgViewPrtLandPage';
import SmViewPrtLandPage from '../components/SmViewPrtLandPage';


function SignedInPage({special=null}) {

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { search } = useLocation();

    let [accessToken, setAccessToken] = useState(null);
    let [tenantSettings, setTenantSettings] = useState(null);
    let [firstTimeUser, setFirstTimeUser] = useState(false);
    let [loading, setLoading] = useState(true);
    let [curNav, setCurNav] = useState();
    let [notifications, setNotifications] = useState();
    let [supportModalShown, setSupportModalShown] = useState(false);
    let [tooltip, setTooltip] = useState([]);
    let [apiError, setApiError] = useState(null);
    let [queryRequest, setQueryRequest] = useState(null);
    let [showNav, setShowNav] = useState(false);
    let [windowSize, setWindowSize] = useState(window.innerWidth);
    let [supportContext] = useState();

    const history = useHistory();
    const { path } = useRouteMatch();

    const request = {
        scopes: ["User.Read"]
    };

        const setSupportModalIsShown = (newVal) => {
        setSupportModalShown(!!newVal);
    }

    const getNewScreenWidth = (screenSize) => {
        setWindowSize(screenSize);
    }


    const getAccessToken = async (instance, account) => {
        try {
            let theAccToken = await instance.acquireTokenSilent({ ...request, account: account });
            setAccessToken(theAccToken);
            return theAccToken;
        } catch (error) {
            if (error.name === "InteractionRequiredAuthError") {
                try {
                    return await instance.acquireTokenPopup({ ...request, account: account });
                } catch (err) {
                    console.log('error: ', err);
                }

            } else {
                console.error(error);
            }
        }

    }

    //Filters list of notification to only show notifications not stored in localStorage
    const filterLocalStorageNotes = (notes, userSettings) => {
        let tempArr = {
            data: []
        }

        if (notes && notes.data && notes.data.length > 0) {
            notes.data.forEach((notification) => {
                //checks to see if notification and userID exists in local storage.
                let localStrNote = localStorage.getItem(`${notification.contentID}${userSettings.ourUserID}`);
                //if not it is added to temp array and added to local storage
                if (!localStrNote) {
                    tempArr.data.push(notification);
                    localStorage.setItem(`${notification.contentID}${userSettings.ourUserID}`, notification.contentID)
                }
            })
            setNotifications(tempArr)
        } else {
            setNotifications(notes)
        }
    }

    //Gets a list of notifications to alert the user with
    const getNotifications = async (token, wkspID, userSettings) => {

        if (!token) {
            token = utils.getAccessToken(instance, accounts[0], request)
        }
        let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/notifications?spc_id=${wkspID}`, {
            headers: {
                "Authorization": "Bearer " + token.accessToken
            }
        });
        let wkspNotifications = await response.json();
        //sends list of notifications to filter to only show ones user has not seen.
        filterLocalStorageNotes(wkspNotifications, userSettings);
    }

    const getTooltip = async () => {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/cms-content?contenttype=tooltips`, {});
        let jsonresponse = await response.json();
        setTooltip(jsonresponse.items);
    }
    
    const onQuery = (query) => {
        setQueryRequest(query);
        history.push(`${path}search/${query}?pg=home`);
    }


    useEffect(() => {
        let curAccess;
        try {
            (async () => {
                let tid = utils.getForceTenantValue();

                const urlParams = new URLSearchParams(search);

                if (urlParams.has('tenid') && urlParams.get('tenid').length > 0) {
                    utils.setForceTenantValue(urlParams.get('tenid'));
                    tid = utils.getForceTenantValue();
                }

                setLoading(true);

                if (!accessToken) {
                    curAccess = await getAccessToken(instance, accounts[0]);
                }

                // if (curAccess) {
                //     await utils.setAccessTokenForPyramid(curAccess);
                // }
                if(!tooltip) {
                    getTooltip();
                }
                
                // Get the return from getTenantInfo
                const settings = await tenantUtils.getTenantInfo(curAccess, tid, special)

                // if tenant = null and first time user = true, then show the first time user modal
                if(settings.tenant === null && settings.firstTime === true) {
                    setFirstTimeUser(true);
                }

                if (typeof settings === 'string') {
                    // If it was a string then the status was something other than 200
                    // so set the apiError to the string.
                    setApiError(settings)
                } else {
                    setTenantSettings(settings);

                    //catch self-signed-up users that haven't been truly added to tenant yet
                    if (settings && settings.firstTime && settings.firstTime === true) {
                        setFirstTimeUser(true);
                    }
                }
                if (settings) {
                    getNotifications(curAccess, tid, settings)
                }
                setLoading(false);
            }
            )()

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [isAuthenticated]);
      

    const showNavHandler = () => {
        setShowNav(!showNav);
    };

    const onLinkClick = (navLink) => {
        setCurNav(navLink);
    };

    // Needed for Workspaces with the Alchemy app
    // Forces token reset so user can sign out and is dirrected to the sign in page. 
    const resetToken = () => {
        setAccessToken(null);
    };

    //if they self-signed and are viewing this page for the first time
    //intercept and fill up with fake app stuff

    return (
        loading === true && windowSize ?
            <Spinner animation='border' />
            :
            tenantSettings && isAuthenticated === true ?
                <div className={tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields ? tenantSettings.tenant.fields.tenantslug : ''}>

                    {
                        windowSize > 992 ?
                            <LgViewPrtLandPage
                                tenantSettings={tenantSettings} 
                                accounts={accounts} 
                                notifications={notifications} 
                                apiError={apiError} 
                                tooltip={tooltip} 
                                showNav={showNav} 
                                windowSize={windowSize} 
                                curNav={curNav} 
                                firstTimeUser={firstTimeUser} 
                                accessToken={accessToken} 
                                isAuthenticated={isAuthenticated} 
                                queryRequest={queryRequest} 
                                supportModalShown={supportModalShown} 
                                onQuery={onQuery} 
                                setSupportModalIsShown={setSupportModalIsShown} 
                                getNewScreenWidth={getNewScreenWidth} 
                                resetToken={resetToken} 
                                SignInPageRoutes={SignInPageRoutes}
                                showNavHandler={showNavHandler}
                                onLinkClick={onLinkClick}
                            />
                        : 
                            <SmViewPrtLandPage
                                tenantSettings={tenantSettings} 
                                showNav={showNav} 
                                setShowNav={setShowNav} 
                                showNavHandler={showNavHandler} 
                                windowSize={windowSize} 
                                setSupportModalIsShown={setSupportModalIsShown} 
                                onLinkClick={onLinkClick} 
                                firstTimeUser={firstTimeUser}
                                onQuery={onQuery}
                                getNewScreenWidth={getNewScreenWidth}
                                resetToken={resetToken}
                                accounts={accounts}
                                notifications={notifications}
                                apiError={apiError}
                                tooltip={tooltip}
                                curNav={curNav}
                                accessToken={accessToken}
                                isAuthenticated={isAuthenticated}
                                queryRequest={queryRequest}
                                supportModalShown={supportModalShown}
                            />
                    }
                    <SupportModal
                        userEmail={accounts && accounts.length > 0 ? accounts[0].username : ''}
                        show={supportModalShown}
                        onSubmitted={setSupportModalIsShown}
                        contextInfo={supportContext}
                    />
                    {
                        firstTimeUser && firstTimeUser === true ?
                            <FirstTimeUserModal
                                userID={tenantSettings && tenantSettings.ourUserID ? tenantSettings.ourUserID : ''}
                            />
                            : null
                    }
                </div>
                : ''
    )
}

export default SignedInPage