import React, { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";
import { Spinner } from 'react-bootstrap';

import '../styles/main.scss'

import SignedOutPage from './SignedOutPage';
import SignedInPage from './SignedInPage';

function MainPage(props) {

    // main page is for setting up our stuff

    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();     


    let [ loading, setLoading ] = useState(true);

    useEffect( () => {
        if(inProgress === InteractionStatus.Login ) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [ inProgress ])

    return (
        loading ?
            <Spinner animation='border' className="text-center"/>
        : 
            isAuthenticated ?
                <SignedInPage special={props.special} />
            :
                <SignedOutPage special={props.special}/>
    )
}

export default MainPage
